import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FooterTikTokLogo = () => {
  return (
    <SvgIcon viewBox='0 0 20 23' fontSize={'small'}>
      <g clipPath='url(#clip0_432_6059)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.4143 0.0193164C14.4156 0.370156 14.5184 5.44049 19.8554 5.75674C19.8554 7.21894 19.8572 8.2791 19.8572 9.67437C19.453 9.69773 16.3436 9.47268 14.4084 7.75172L14.4026 15.3786C14.476 20.6721 10.5687 23.8935 5.46144 22.7808C-3.34542 20.1542 -0.418961 7.13135 8.42395 8.52527C8.42395 12.7295 8.4262 8.52392 8.4262 12.7295C4.77297 12.1936 3.55102 15.2236 4.522 17.3928C5.40512 19.3681 9.04258 19.7962 10.3114 17.0097C10.4551 16.4643 10.5268 15.8426 10.5268 15.1445V0L14.4143 0.0193164Z'
          fill='#121127'
          fillOpacity='0.56'
        />
      </g>
      <defs>
        <clipPath id='clip0_432_6059'>
          <rect width='19.7143' height='23' fill='white' transform='translate(0.142822)' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default FooterTikTokLogo;
