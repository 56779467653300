import { styled } from '@mui/material/styles';
import { Button } from 'gatsby-theme-material-ui';

const LiveBashButton = styled(Button)<{ $round?: boolean }>`
  color: ${({ theme }) => theme.colors.deepBlue};
  font-size: 0.875rem;
  padding: 0.375rem 1.25rem;
  background-color: ${({ theme }) => theme.colors.cyan550};
  height: 3.25rem;
  border-radius: ${({ $round }) => ($round ? '2.5rem' : '0.5rem')};

  &&&.add-padding {
    padding: 0.875rem 2rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.yellow900};
  }

  svg {
    margin-left: 0.25rem;
  }

  &&&.ripple {
    color: ${({ theme }) => theme.colors.deepBlue};
    font-size: 0.875rem;
    padding: 0.375rem 1.25rem !important;
    background-color: ${({ theme }) => theme.colors.yellow900};
    height: 3.25rem;

    &&&.ripple {
      background: ${({ theme }) => theme.colors.cyan550};
      color: ${({ theme }) => theme.colors.deepBlue};
      padding: 20px 23px;
      position: relative;
      overflow: hidden;
    }

    &&&.ripple:hover {
      background: ${({ theme }) => theme.colors.cyan550};
      color: ${({ theme }) => theme.colors.deepBlue};
    }

    &&&.ripple::before {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.colors.yellow900};
      width: 10%;
      height: 25%;
      border-radius: 50%;
      z-index: 1;
      top: 35%;
      left: 45%;
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    }

    &&&.ripple:hover::before {
      -webkit-transform: scale(12);
      transform: scale(12);
      -webkit-transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: border-radius 0.5s 0.5s, -webkit-transform 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s;
      transition: transform 0.5s, border-radius 0.5s 0.5s, -webkit-transform 0.5s;
    }

    & > span {
      z-index: 50;
      padding: 0;
    }
  }
`;

export default LiveBashButton;
