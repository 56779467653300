import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const FooterFacebookLogo = () => {
  return (
    <SvgIcon viewBox='0 0 25 24' fontSize={'small'}>
      <g clipPath='url(#clip0_428_5715)'>
        <path
          d='M24.1429 12.0006C24.1425 9.70738 23.4851 7.46232 22.2484 5.53116C21.0118 3.6 19.2477 2.06362 17.165 1.1039C15.0823 0.144184 12.7682 -0.198683 10.4967 0.115894C8.22523 0.43047 6.09143 1.38932 4.34794 2.87892C2.60444 4.36852 1.32428 6.3265 0.658987 8.52105C-0.00630219 10.7156 -0.0288528 13.0548 0.594005 15.2618C1.21686 17.4688 2.45905 19.4511 4.1735 20.974C5.88795 22.4969 8.00287 23.4967 10.2679 23.8551V15.4693H7.22138V12.0006H10.2679V9.35681C10.2679 6.34931 12.0596 4.68806 14.8001 4.68806C15.7001 4.70054 16.598 4.77875 17.4866 4.92206V7.87556H15.9731C15.7152 7.84131 15.4529 7.86553 15.2056 7.94643C14.9584 8.02734 14.7325 8.16285 14.5447 8.34293C14.3569 8.52301 14.212 8.74303 14.1208 8.9867C14.0297 9.23037 13.9945 9.49144 14.0179 9.75056V12.0006H17.3464L16.8139 15.4693H14.0179V23.8551C16.8407 23.4085 19.4115 21.9691 21.2676 19.796C23.1238 17.6228 24.1433 14.8585 24.1429 12.0006Z'
          fill='#121127'
          fillOpacity='0.56'
        />
      </g>
      <defs>
        <clipPath id='clip0_428_5715'>
          <rect width='24' height='24' fill='white' transform='translate(0.142883)' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default FooterFacebookLogo;
