import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import LiveBashButton from 'components/shared/atoms/LiveBashButton/LiveBashButton';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { MailchimpFormData } from './MailchimpSignup';

const SectionContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.liveBashGrey};
  padding: 3rem 0;
  z-index: 100;
`;

const FlexContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-item: center;
`;

const CopyContainer = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex: 0 0 100%;
  }
`;

const FormContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 1.5rem;
    flex: 0 0 100%;
  }
`;

const StackedFullWidth = styled('div')`
  display: flex;
  width: 100%;
`;

const StyledTitle = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.deepBlue};
  `
);

const StyledText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.deepBlue};
  `
);

interface MailchimpSignupProps {
  status: string | null;
  message: string | Error | null;
  subscribe: (data: MailchimpFormData) => void;
}

type Props = MailchimpSignupProps;

const SUCCESS = 'success';

const MailchimpBanner = ({ status, subscribe, message }: Props) => {
  const [email, setEmail] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (status) {
      setShowAlert(true);
      if (status === SUCCESS) {
        setEmail('');
      }
    }
  }, [status, message]);

  return (
    <SectionContainer>
      <FlexContainer>
        <CopyContainer>
          <StyledTitle variant='h3'>Stay In Touch</StyledTitle>
          <StyledText>Sign up for news on upcoming events, performances, and more.</StyledText>
        </CopyContainer>
        <FormContainer>
          <Stack direction='column' spacing={2} sx={{ width: '100%' }}>
            {showAlert && status && message && (
              <StackedFullWidth>
                <Alert
                  severity={status === SUCCESS ? 'success' : 'warning'}
                  sx={{ flex: '1' }}
                  action={
                    <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert(false)}>
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  {message}
                </Alert>
              </StackedFullWidth>
            )}
            <StackedFullWidth>
              <TextField
                label='Enter email address'
                name='email'
                value={email}
                size='medium'
                sx={{ flex: '1', marginRight: '0.5rem' }}
                onChange={(email) => setEmail(email.target.value)}
              />
              <LiveBashButton
                variant='contained'
                className='ripple'
                onClick={() => {
                  subscribe({ EMAIL: email });
                }}
              >
                <span>Join the List</span>
              </LiveBashButton>
            </StackedFullWidth>
          </Stack>
        </FormContainer>
      </FlexContainer>
    </SectionContainer>
  );
};

export default MailchimpBanner;
