import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { GatsbyLink } from 'gatsby-theme-material-ui';
import {
  FACEBOOK_SOCIAL_URL,
  INSTAGRAM_SOCIAL_URL,
  LINKEDIN_SOCIAL_URL,
  SUPPORT_URL,
  TIKTOK_SOCIAL_URL,
  YOUTUBE_SOCIAL_URL,
} from 'utils/constants';
import FooterLogo from './assets/FooterLogoDark.svg';
import FooterFacebookLogo from './assets/FooterFacebook';
import FooterTikTokLogo from './assets/FooterTikTok';
import FooterInstagramLogo from './assets/FooterInstagram';
import FooterYouTubeLogo from './assets/FooterYouTube';
import FooterLinkedInLogo from './assets/FooterLinkedIn';

const StyledFooter = styled('footer')`
  z-index: 100;
`;

const FooterCopy = styled(Typography)`
  padding: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 180%;
`;

const SocialContainer = styled('div')`
  padding: 0 0.25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const NavigationText = styled('div')`
  font-size: 1rem;
  font-weight: 400;
  color: rgba(18, 17, 39, 0.56);
  margin-bottom: 0.5rem;

  :hover {
    color: ${({ theme }) => theme.colors.brightRed};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    margin-bottom: 0.75rem;
  }
`;

const NavigationLinkContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 70%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const FooterLogoContainer = styled('div')`
  width: 11.9375rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    flex: 0 0 100%;
    margin-bottom: 2rem;
  }
`;

const FooterLinksContainer = styled('div')`
  width: 11.9375rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

const NavigationSocialContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;

  width: 30%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: fit-content;
    margin: 2.75rem auto 1rem;
    align-items: flex-start;
    flex-direction: row;
  }
`;

const ParentSocialContainer = styled('div')`
  width: 19.625rem;
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  text-decoration: none;
`;

const StyledA = styled('a')`
  text-decoration: none;
  :hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.cyan550};
        fill-opacity: 1;
      }
      g {
        path {
          fill: ${({ theme }) => theme.colors.cyan550};
          fill-opacity: 1;
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '3rem', paddingTop: '3rem' }}>
          <NavigationLinkContainer>
            <FooterLogoContainer>
              <img style={{ width: '4rem', height: 'auto' }} src={FooterLogo} alt='LiveBash Logo' />
            </FooterLogoContainer>
            <FooterLinksContainer>
              <StyledGatsbyLink to={'/fan/'}>
                <NavigationText>Fans</NavigationText>
              </StyledGatsbyLink>
              <StyledGatsbyLink to={'/performer/'}>
                <NavigationText>Performers</NavigationText>
              </StyledGatsbyLink>
              <StyledGatsbyLink to={'/about/'}>
                <NavigationText>About</NavigationText>
              </StyledGatsbyLink>
              <StyledGatsbyLink to={'/articles/'}>
                <NavigationText>Articles</NavigationText>
              </StyledGatsbyLink>
            </FooterLinksContainer>
            <FooterLinksContainer>
              <StyledA href={SUPPORT_URL} target={'_blank'} rel={'noopener noreferrer'}>
                <NavigationText>Support</NavigationText>
              </StyledA>
              <StyledGatsbyLink to={'/privacy'}>
                <NavigationText>Privacy Policy</NavigationText>
              </StyledGatsbyLink>
              <StyledGatsbyLink to={'/terms-and-conditions'}>
                <NavigationText>Terms & Conditions</NavigationText>
              </StyledGatsbyLink>
            </FooterLinksContainer>
          </NavigationLinkContainer>
          <NavigationSocialContainer>
            <ParentSocialContainer>
              <SocialContainer>
                <StyledA href={TIKTOK_SOCIAL_URL} target={'_blank'} rel={'noopener noreferrer'}>
                  <FooterTikTokLogo />
                </StyledA>
                <StyledA href={INSTAGRAM_SOCIAL_URL} target={'_blank'} rel={'noopener noreferrer'}>
                  <FooterInstagramLogo />
                </StyledA>
                <StyledA href={YOUTUBE_SOCIAL_URL} target={'_blank'} rel={'noopener noreferrer'}>
                  <FooterYouTubeLogo />
                </StyledA>
                <StyledA href={FACEBOOK_SOCIAL_URL} target={'_blank'} rel={'noopener noreferrer'}>
                  <FooterFacebookLogo />
                </StyledA>
                <StyledA href={LINKEDIN_SOCIAL_URL} target={'_blank'} rel={'noopener noreferrer'}>
                  <FooterLinkedInLogo />
                </StyledA>
              </SocialContainer>
              <FooterCopy color='rgba(18, 17, 39, 0.56)'>© 2023 Live Bash, Inc. All rights reserved</FooterCopy>
            </ParentSocialContainer>
          </NavigationSocialContainer>
        </Box>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
